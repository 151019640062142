.div_blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin: 20px;
  padding-left: 30px;
}

.div_blog h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #000;
  margin: 10px;
  padding-left: 5px;
}

.div_blog h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000;
  margin: 7px;
  padding-left: 10px;
}

.div_blog h3 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #000;
  margin: 5px;
  padding-left: 25px;
}

.div_blog h4 {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
  margin: 3px;
  padding-left: 50px;
}

.div_blog p {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  margin: 2px;
  padding-left: 80px;
  padding-right: 300px;
  justify-content: flex-start;
}

.button_blog {
  height: 50px;
  width: 200px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff6b6b; /* Color de fondo cálido */
  color: #fff; /* Texto en color blanco */
  border: 2px solid #ff6b6b; /* Borde del botón */
  border-radius: 4px; /* Esquinas redondeadas */
  text-decoration: none; /* Quita el subrayado de los enlaces */
  font-size: auto;
  font-weight: bold; /* Texto en negrita */
  transition: background-color 0.5s, color 0.5s, transform 0.5s; /* Transiciones suaves al pasar el ratón */
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.button_blog:hover {
  background-color: #ff8b8b; /* Color de fondo cálido más claro */
  color: #6a6666;
  transform: scale(1.1);
}

.blog_link {
  text-decoration: none;
  color: #fff;
}

.div_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.blog_principal_div h1 {
  display: flex;
  margin: 20px;
}

.blog_link_prin {
  color: #000;
  margin: 20px;
  font-weight: 600;
  padding-left: 30px;
  text-decoration: dotted;
}

.ul_blog {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.li_blog {
  margin: 10px;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .div_blog {
    padding-left: 10px; /* Adjusted padding for mobile */
  }

  .div_blog h1,
  .div_blog h2,
  .div_blog h3,
  .div_blog h4,
  .div_blog p {
    padding-left: 10px; /* Adjusted padding for mobile */
    padding-right: 10px; /* Adjusted padding for mobile */
  }

  .button_blog {
    width: 100%; /* Full width for mobile */
  }

  .blog_link_prin {
    padding-left: 10px; /* Adjusted padding for mobile */
  }
}
