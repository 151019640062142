.dropzone {
  height: 75vh;
  min-width: 300px;
  width: 100%;
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); */
  /* background:linear-gradient(to bottom, #3953a241, #6631a32a); */
  align-items: center;
  justify-content: center;
  display: flex;
  color: #888;
}
.ad_banner {
  height: 15vh;
}

.select_pdf_h2 {
  justify-content: center;
  margin: 10px;
  padding: 10px;
  /* width: 40%; */
  font-size: 20px;
  font-weight: 400;
  color: #888;
}

.dropzone.dragging {
  background-color: #888;
  color: aliceblue;
}

.set_button_select {
  width: 70%;
}

.ad {
  flex: 1;
  width: 100%;
  background-color: transparent;
}

.button_select_file {
  height: 75px;
  width: 200px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff6b6b; /* Color de fondo cálido */
  color: #fff; /* Texto en color blanco */
  border: 2px solid #ff6b6b; /* Borde del botón */
  border-radius: 10px; /* Esquinas redondeadas */
  text-decoration: none; /* Quita el subrayado de los enlaces */
  font-size: 19px;
  font-weight: bold; /* Texto en negrita */
  transition: background-color 0.5s, color 0.5s, transform 0.5s; /* Transiciones suaves al pasar el ratón */
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.button_select_file:hover {
  background-color: #ff8b8b; /* Color de fondo cálido más claro */
  color: #6a6666;
  transform: scale(1.1);
}

.pdf_preview_list {
  flex: 4;
  height: 90%;
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin: 5px;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

/* Style the scroll bar track */
.pdf_preview_list::-webkit-scrollbar {
  width: 5px; /* Adjust the width as needed */
}

/* Style the scroll bar thumb (the draggable part) */
.pdf_preview_list::-webkit-scrollbar-thumb {
  background: #ff6b6b; /* Set the background color to blue */
  border: 5px solid #ff6b6b; /* Optional: Add a border to the thumb */
}

.container_select_properties_pdf {
  height: 100%;
}

.download_pdf {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reload_tag {
  margin: 10px;
  text-decoration: underline;
  color: #888;
}

.download_tag {
  visibility: hidden;
}

.button_and_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.share-buttons{
  margin: 10px;
}

.share-buttons button{
  margin: 10px;
}

.share-buttons button:hover {
  background-color: #3498db; /* Cambia el color de fondo al pasar el ratón */
  color: #fff; /* Cambia el color del texto al pasar el ratón */
}

.share-buttons .facebook {
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  color: #fff;
  background-color: #3b5998;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-image: url('../img/facebook.svg');
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  /* Color de fondo de Twitter */
}

.share-buttons .twitter {
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  color: #fff;
  background-color: #55acee;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-image: url('../img/twitter.svg');
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  /* Color de fondo de Twitter */
}

.share-buttons .linkedin {
  height: 35px;
  width: 35px;
  display: inline-block;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  color: #fff;
  background-color: #0077b5;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  background-image: url('../img/linkedin.svg');
  background-size: cover; /* Adjusts the background image size */
  background-position: center; /* Centers the background image */
  border: none;
  cursor: pointer;
  /* Color de fondo de Twitter */
}

.tooltip {
  background-color: #000000; /* Light gray background */
  color: #ffffff; /* Dark text color */
  border: 1px solid #ddd; /* Light border */
  border-radius: 4px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
  font-size: 14px; /* Adjust the font size */
  padding: 8px 12px; /* Add padding for spacing */
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .dropzone {
    height: 100vh; /* Adjust height for mobile */
    /* max-height: 100vh; */
    min-width: auto;
    /* width: 50vw; Remove minimum width for mobile */
  }

  .container_select_properties_pdf {
    width: 40vw;
  }

  .button_select_file {
    width: 80%;
    font-size: medium; /* Full width for mobile */
  }

  .pdf_preview_list {
    min-width: auto; /* Remove minimum width for mobile */
  }

  .ad {
    display: none;
  }

  .ad_banner {
    display: none;
  }
}
