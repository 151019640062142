.footer_div {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer_text {
  color: #f94242;
  font-size: 15px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  margin: 15px;
}

.footer_links {
  margin: 10px;
  text-decoration: underline;
  color: #888;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .footer_text {
    font-size: 14px; /* Adjusted font size for mobile */
    margin: 10px; /* Adjusted margin for mobile */
  }

  .footer_links {
    margin: 5px; /* Adjusted margin for mobile */
  }
}
