.header {
  height: 70px;
  align-items: center;
  justify-content: start;
  width: 100%;
  background-color: #f94242;
  /* background: linear-gradient(to bottom, #FF6B6B, #FF8B8B); */
  display: flex;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
}

.title {
  align-items: center;
  justify-content: center;
  color: whitesmoke;
  font-size: 24px;
  font-weight: bold;

  margin: 10px;
  text-decoration: none;
}

.header_button {
  background-color: transparent;
  border: none;
  color: antiquewhite; /* Text color */
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  transition: border-color 0.5s ease; /* Smooth transition for the underline effect */
  border-radius: 5px 5px 0 0;
}

/* White underline effect on hover or focus */
.header_button:hover {
  border-bottom: 2px solid white; /* White underline */
  background: linear-gradient(to bottom, transparent, #ffffff55);
  outline: none; /* Remove default focus outline */
}

.logo {
  height: 50%;
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  margin-left: 30px;
  width: auto;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.blog {
  color: #ffffff; /* White text color for contrast */
  text-decoration: none; /* Remove default underline */
  font-weight: bold; /* Make the text bold */
  font-size: 18px; /* Adjust the font size */
  transition: color 0.3s ease-in-out; /* Smooth color transition on hover */
  display: inline-block; /* Make the link a block element for padding */

  /* Add additional styles for better visibility and aesthetics */
  margin-left: 30px;
  padding: 8px 8px; /* Add padding for spacing */
  border-radius: 6px; /* Add rounded corners */
  background-color: #ff8f8f; /* Lighter red background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle box shadow */
}

/* Hover Effect */
.blog:hover {
  color: #ffffff; /* White text color on hover */
  background-color: #ff6b6b; /* Red background color on hover */
}

.only_pdf_links{
  margin: 5px;
  padding: 5px;
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  color: #ffffff; /* White text color for contrast */
  text-decoration: none; /* Remove default underline */
  font-weight: 400; /* Make the text bold */
  font-size: 20px; /* Adjust the font size */
  display: inline-block;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  align-items: center;
  position: relative; /* Needed for the ::after pseudo-element */
}

.only_pdf_links:hover {
  transform: scale(1.1);
}

.only_pdf_links::after {
  content: ""; /* The content property is mandatory for ::after */
  position: absolute;
  bottom: 0; /* Position the line at the bottom of the element */
  left: 0;
  width: 100%; /* Make the line as wide as the element */
  height: 2px; /* Adjust the thickness of the line */
  background-color: #ffffff; /* Make the line white */
  transform: scaleX(0); /* Initially, the line has no width */
  transition: transform 0.3s ease-in-out; /* Smooth width transition on hover */
}

.only_pdf_links:hover::after {
  transform: scaleX(1); /* When the element is hovered over, the line takes the full width */
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .title {
    font-size: 18px; /* Adjusted font size for mobile */
  }

  .header{
	width: 100%;
  justify-content: center;
  padding-left: 0px;
  }

  .header_button {
    font-size: 14px; /* Adjusted font size for mobile */
    padding: 10px; /* Adjusted padding for mobile */
  }

  .logo {
    height: 50%; /* Adjusted height for mobile */
  }

  .blog {
    font-size: 16px; /* Adjusted font size for mobile */
    margin-left: 5px; /* Adjusted margin for mobile */
  }
}
