.preview_container {
  min-width: 100px;
  max-width: 250px;
  height: 200px;
  margin: 5px;
  align-items: center;
  border: 1px solid gray; /* Add a light border to the image container */
  text-align: center; /* Center the content horizontally */
  background: linear-gradient(
    to bottom,
    #ffffff,
    #ff6b6b60
  ); /* Add a light background color */
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
  border-radius: 5px; /* Round the corners */
  display: block;
  padding: 5px;
  transition: background-color 0.5s, color 0.5s, transform 0.5s;
}

.preview_container:hover {
  background: linear-gradient(
    to bottom,
    #ffffff,
    #ff6b6b
  ); /* Add a light background color */
  color: #333;
  transform: scale(1.03);
}

.p_preview {
  height: 10%;
  margin: 0;
  padding: 0;
  color: #888;
  font-weight: bold;
}

.preview_separator {
  width: 100%;
  height: 90%;
  align-items: center;
  display: flex;
}

.preview_image {
  flex: 1;
  max-width: auto; /* Ensure the image doesn't exceed its original size */
  height: 90%;
  object-fit: contain;
  border: 2px solid #ccc;
}

.preview_image_svg {
  flex: 1;
  max-width: auto; /* Ensure the image doesn't exceed its original size */
  height: 60%;
  object-fit: contain;
  /* Optional: Add a border for clarity */
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .preview_container {
    width: 100%;
    min-width: 190px; /* Adjusted max-width for smaller screens */
    height: 150px; /* Allow the container to adjust its height based on content */
  }

  /* .preview_image,
  .preview_image_svg {
    height: 80%; 
  } */
}
