.policy_legal {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 20px;
}

.policy_legal_h1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #333;
}

.policy_legal h1 {
  margin-left: 40px;
  font-size: 24px;
  color: #333;
}

.policy_legal h2 {
  margin-left: 70px;
  font-size: 18px;
  color: #555;
}

.policy_legal p {
  margin-left: 100px;
  font-size: 16px;
  color: #777;
  line-height: 1.5;
}

.policy_legal li {
  margin-left: 100px;
  font-size: 16px;
  color: #777;
  line-height: 1.5;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .policy_legal {
    align-items: start; /* Reset align-items for larger screens */
  }

  .policy_legal h1,
  .policy_legal h2,
  .policy_legal p,
  .policy_legal li {
    margin-left: 20px; /* Adjusted margin for mobile */
  }
}
