.howto_div_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(96, 96, 96);
  font-weight: 500;
}

.howto_div_odd {
  width: 80%;
  height: 150px;
  max-height: 300px;
  display: flex;
  align-items: center;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 30%,
    #ff6b6b9b 100%
  );
  border: 1px solid #ff6b6b;
  border-radius: 10px;
  padding: 10px;
  margin: 15px;
}

.howto_div_even {
  width: 80%;
  height: 150px;
  display: flex;
  align-items: center;
  background: linear-gradient(45deg, #ff6b6b9b 0%, rgba(255, 255, 255, 0) 70%);
  border: 1px solid #ff6b6b;
  border-radius: 10px;
  padding: 10px;
  margin: 15px;
}


.howto_div_even img {
  width: auto;
  height: 200%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.howto_div_odd img {
  width: auto;
  height: 200%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.howto_text_div {  
  width: 100%;
  height: 100%;
  text-overflow:ellipsis;
  overflow: hidden;
  margin: 10px;
}

#img_custom_ranges {
  height: 150%;
}

#img_fixed_ranges {
  height: 140%;
}

#img_edit_ranges {
  height: 100%;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .howto_div_container img {
    display: none;
  }
}
